// Imports => React
import React, { useState, useEffect, useMemo, memo, useRef } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Constants
import {
  COLUMNS,
  DATETIME_FORMATS,
  ICONS,
  KEYS,
  PERMISSIONS,
  SETTINGS,
  THEMES,
  TITLES,
  TYPES,
  VARIANTS,
  VISUALS,
} from '@constants';

// Imports => Utilities
import {
  AcUUID,
  AcIsSet,
  AcSortBy,
  AcFormatDate,
  AcFormatInternalURI,
} from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Components
const AcTable = loadable(() => import('@components/ac-table/ac-table.web'));
const AcDetailsCard = loadable(() =>
  import('@components/ac-details-card/ac-details-card.web')
);

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
const AcHeading = loadable(() => import('@atoms/ac-heading/ac-heading.web'));
const AcRichContent = loadable(() =>
  import('@atoms/ac-rich-content/ac-rich-content.web')
);
const AcCard = loadable(() => import('@atoms/ac-card/ac-card.web'));
const AcEmptyBlock = loadable(() =>
  import('@atoms/ac-empty-block/ac-empty-block.web')
);
const AcToggleInput = loadable(() =>
  import('@atoms/ac-toggle-input/ac-toggle-input.web')
);
const AcLoader = loadable(() => import('@atoms/ac-loader/ac-loader.web'));

const _CLASSES = {
  MAIN: 'ac-control-unit-detail-settings-tab',
};

const AcControlUnitDetailSettingsTab = ({
  data,
  store: { control_units, ui },
}) => {
  const { is_loading, is_busy } = control_units;
  const { can, cannot } = usePermissions();
  const didMount = useRef(false);

  const [liveview, setLiveview] = useState(false);

  const getRawFieldValues = useMemo(() => {
    if (data?.equipment_type?.series === '36') {
      return {
        extract: AcIsSet(data?.extract) ? data?.extract : true,
        restrike: AcIsSet(data?.restrike) ? data?.restrike : false,
        horizontal_piling: AcIsSet(data?.horizontal_piling)
          ? data?.horizontal_piling
          : true,
      };
    }

    return {
      extract: AcIsSet(data?.extract) ? data?.extract : false,
      restrike: AcIsSet(data?.restrike) ? data?.restrike : false,
      horizontal_piling: AcIsSet(data?.horizontal_piling)
        ? data?.horizontal_piling
        : false,
    };
  }, [
    data?.equipment_type?.series,
    data?.extract,
    data?.restrike,
    data?.horizontal_piling,
  ]);

  let raw_fields = getRawFieldValues;

  const [fields, setFields] = useState(raw_fields);

  const { handleInputChange } = useFormActions({
    fields,
    setFields,
  });

  useEffect(() => {
    if (AcIsSet(data) && AcIsSet(fields)) {
      if (didMount.current) {
        control_units.update(data.id, { ...fields }).catch((error) => {
          control_units.get_by_id(data.id);
        });
      } else {
        didMount.current = true;
      }
    }
  }, [fields]);

  const renderOperatorsTable = useMemo(() => {
    if (is_loading) return <AcLoader loading={true} />;
    if (!AcIsSet(data.last_send_configuration))
      return <AcEmptyBlock message={'No results found to display.'} />;

    const {
      last_send_configuration: { customers },
    } = data;

    if (!customers || customers.length === 0)
      return <AcEmptyBlock message={'No results found to display.'} />;

    const rows = AcSortBy({
      collection: customers,
      field: 'name',
      direction: KEYS.ASCENDING,
    });

    const options = {
      columns: COLUMNS.CONTROL_UNIT_OPERATORS,
      rows,
      pagination: null,
    };

    return (
      <Fade duration={300}>
        <AcTable {...options} />
      </Fade>
    );
  }, [data.last_send_configuration, is_loading]);

  const getLastSendConfigurationTimestamp = useMemo(() => {
    if (!AcIsSet(data)) return null;
    if (!AcIsSet(data.last_send_configuration)) return null;

    const { created_at } = data.last_send_configuration;
    const formatted = AcFormatDate(
      created_at,
      null,
      DATETIME_FORMATS.DATETIME_WITH_YEAR_AND_SECONDS
    );

    return formatted;
  }, [data.last_send_configuration, is_loading]);

  const renderLastSentConfiguration = useMemo(() => {
    if (!AcIsSet(data)) return null;
    if (!AcIsSet(data.last_send_configuration)) return null;
    if (!AcIsSet(getLastSendConfigurationTimestamp)) return null;

    const { id, name, configuration_name, configuration, last_send_by } =
      data.last_send_configuration;

    const configuration_route =
      (configuration &&
        configuration.id &&
        configuration_name &&
        AcFormatInternalURI(
          { id: configuration.id, entity: KEYS.CONFIGURATION },
          'View configuration'
        )) ||
      null;

    const items = [
      {
        label: 'Name',
        value: configuration_name || '-',
        type: TYPES.LINK,
        to: configuration_route,
      },
      {
        label: 'Last sent',
        value: getLastSendConfigurationTimestamp || '-',
      },
      {
        label: 'Last sent by',
        value: (last_send_by && last_send_by.name) || '-',
      },
    ];

    return (
      <AcDetailsCard title={TITLES.LAST_SENT_CONFIGURATION} items={items} />
    );
  }, [
    data.last_send_configuration,
    getLastSendConfigurationTimestamp,
    is_loading,
  ]);

  const renderSettings = useMemo(() => {
    if (!AcIsSet(data)) return null;

    const collection = [
      { key: SETTINGS.EXTRACT, label: 'Extract' },
      { key: SETTINGS.RESTRIKE, label: 'Restrike' },
      {
        key: SETTINGS.HORIZONTAL_PILING,
        label: 'Horizontal piling',
      },
    ];

    const len = collection.length;
    let n = 0;
    let result = [];

    for (n; n < len; n++) {
      const setting = collection[n];
      const disabled = cannot(PERMISSIONS.EQUIPMENT.MANAGE_SETTINGS) || is_busy;
      let callback = null;

      if (!disabled) {
        callback = async (event, name, value, type, selected) => {
          await handleInputChange(event, name, value, type, selected);
        };
      }

      const options = {
        type: TYPES.BOOLEAN,
        name: setting.key,
        id: `ac-settings-toggle-${setting.key}`,
        value: true,
        checked: fields[setting.key],
        callback,
        disabled,
      };

      const label = setting.label;

      const object = (
        <AcRow key={`ac-settings-toggle-row-${setting.key}`}>
          <AcColumn xs={12}>
            <AcToggleInput {...options}>
              <span
                dangerouslySetInnerHTML={{
                  __html: label,
                }}
              />
            </AcToggleInput>
          </AcColumn>
        </AcRow>
      );

      result.push(object);
    }

    return result;
  }, [
    is_busy,
    data,
    fields,
    fields.extract,
    fields.restrike,
    fields.horizontal_piling,
  ]);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  });

  return (
    <div className={getMainClassNames}>
      <AcContainer fluid>
        <AcRow>
          <AcColumn xs={12} sm={6}>
            <AcRow>
              <AcColumn className={'h-margin-bottom-25'}>
                <AcHeading tag={'h2'} rank={5}>
                  Control unit settings
                </AcHeading>
                <AcRichContent
                  content={'<p>Additional control unit settings</p>'}
                />
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn>
                <AcCard>{renderSettings}</AcCard>
              </AcColumn>
            </AcRow>
          </AcColumn>

          {AcIsSet(data) && AcIsSet(data.last_send_configuration) && (
            <AcColumn xs={12} sm={6}>
              <AcRow>
                <AcColumn className={'h-margin-bottom-25'}>
                  <AcHeading tag={'h2'} rank={5}>
                    Last sent configuration
                  </AcHeading>
                  <AcRichContent
                    content={
                      '<p>Details of the latest configuration that has been sent to this control unit</p>'
                    }
                  />
                </AcColumn>
              </AcRow>

              <AcRow>
                <AcColumn>{renderLastSentConfiguration}</AcColumn>
              </AcRow>
            </AcColumn>
          )}
        </AcRow>

        <AcRow>
          <AcColumn xs={12}>
            <AcRow className={'h-margin-bottom-25'}>
              <AcColumn xs={12} sm={8}>
                <AcHeading tag={'h2'} rank={5}>
                  Operators
                </AcHeading>
                <AcRichContent
                  content={
                    '<p>A list of operators who have access to this control unit.</p>'
                  }
                />
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn>
                <AcCard dense flat>
                  {renderOperatorsTable}
                </AcCard>
              </AcColumn>
            </AcRow>
          </AcColumn>
        </AcRow>
      </AcContainer>
    </div>
  );
};

export default withStore(observer(AcControlUnitDetailSettingsTab));
