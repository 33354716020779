// Imports => React
import React, { useState, useEffect, useMemo, memo } from 'react';
import loadable from '@loadable/component';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Constants
import {
  COLUMNS,
  ICONS,
  KEYS,
  PERMISSIONS,
  THEMES,
  TITLES,
  TYPES,
  VARIANTS,
} from '@constants';

// Imports => Utilities
import { AcIsSet, AcSortBy } from '@utils';

// Imports => Hooks
import { usePermissions } from '@hooks';

// Imports => Molecules
import AcAddSoftwareUpdateModal from '@molecules/ac-add-software-update-modal/ac-add-software-update-modal.web';
import AcEditSoftwareUpdateModal from '@molecules/ac-edit-software-update-modal/ac-edit-software-update-modal.web';

// Imports => Components
import AcDetailsCard from '@components/ac-details-card/ac-details-card.web';
import AcHeroVisual from '@components/ac-hero-visual/ac-hero-visual.web';
const AcTable = loadable(() => import('@components/ac-table/ac-table.web'));

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcEmptyBlock from '@atoms/ac-empty-block/ac-empty-block.web';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcCard from '@atoms/ac-card/ac-card.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
  MAIN: 'ac-control-unit-type-detail-software-tab',
};

const AcControlUnitTypeDetailSoftwareTab = ({
  data,
  store: { control_unit_types, ui },
}) => {
  const { can, cannot } = usePermissions();
  const {
    is_loading,
    is_busy,
    current_control_unit_type,
    current_control_unit_type_software,
  } = control_unit_types;

  useEffect(() => {
    if (AcIsSet(data) && AcIsSet(data.id))
      control_unit_types.get_software(data.id);
  }, [data]);

  const handleDownloadSoftware = async (object) => {
    if (!AcIsSet(data)) return;
    if (!AcIsSet(object)) return;

    await control_unit_types.download_software(object).then((response) => {
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.url
      )
        window.open(response.data.data.url);
    });
  };

  const displayAddSoftwareUpdateModal = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: TITLES.NEW_SOFTWARE_UPDATE,
      body: (
        <AcAddSoftwareUpdateModal
          instance={control_unit_types}
          submit={control_unit_types.add_software_update}
          id={current_control_unit_type.id}
          control_unit_type={current_control_unit_type}
        />
      ),
      closeable: true,
      visible: true,
      actions: [],
      callback: async () => {
        await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const handleDelete = (object) => {
    const { name, id } = object;

    ui.confirm({
      instance: control_unit_types,
      title: TITLES.DELETE_SOFTWARE_VERSION,
      content: `<p>You are about to permanently delete software version <strong>${name}</strong>.</p><p class="h-margin-top-15"><strong>Are you sure you want to proceed?</strong></p>`,
      confirm: {
        label: 'Yes, delete',
        callback: () => {
          return new control_unit_types.delete_software({ name, id }).then(
            async () => {
              return await control_unit_types.get_software(data.id);
            }
          );
        },
      },
    });
  };

  const displayEditSoftwareUpdateModal = async (object) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: TITLES.EDIT_SOFTWARE_UPDATE,
      body: (
        <AcEditSoftwareUpdateModal
          instance={control_unit_types}
          submit={control_unit_types.update_software_update}
          id={current_control_unit_type.id}
          control_unit_type={current_control_unit_type}
          data={object}
        />
      ),
      closeable: true,
      visible: true,
      actions: [],
      callback: async () => {
        await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const renderSoftwareTable = useMemo(() => {
    if (control_unit_types.is_busy) return <AcLoader loading={true} />;
    if (
      !AcIsSet(current_control_unit_type_software) ||
      current_control_unit_type_software.length === 0
    )
      return <AcEmptyBlock message={'No software updates found.'} />;

    let collection = current_control_unit_type_software;

    if (cannot(PERMISSIONS.SOFTWARE.READ_UNAVAILABLE)) {
      collection = collection.filter((item) => item.is_available);
    }

    collection = collection.map((item) => {
      let tooltip = null;

      if (AcIsSet(item.release_notes)) {
        tooltip = {
          column: 'name',
          content: `<p>Release notes: <strong>${item.name}</strong></p><p class="h-text--tt-none">${item.release_notes}</p>`,
        };
      }

      return {
        ...item,
        tooltip,
      };
    });

    const rows = AcSortBy({
      collection,
      field: 'released_at',
      direction: 'desc',
    });

    const options = {
      columns: COLUMNS.SOFTWARE,
      rows,
      pagination: null,
      actions: [
        [
          {
            label: 'Edit update details',
            icon: ICONS.PLAYLIST_EDIT,
            callback: displayEditSoftwareUpdateModal,
            disabled: cannot(PERMISSIONS.SOFTWARE.UPDATE),
          },
        ],
        [
          {
            label: 'Push to control units',
            icon: ICONS.CAST,
            disabled: true || cannot(PERMISSIONS.SOFTWARE.PUSH),
            callback: null,
          },
        ],
        [
          {
            label: 'Download software update',
            icon: ICONS.DOWNLOAD_ALT,
            callback: handleDownloadSoftware,
            disabled: cannot(PERMISSIONS.SOFTWARE.DOWNLOAD),
          },
        ],
        [
          {
            label: 'Delete software update',
            icon: ICONS.TRASH_CAN_OUTLINE,
            callback: handleDelete,
            disabled: cannot(PERMISSIONS.SOFTWARE.DELETE),
          },
        ],
      ],
    };

    return (
      <Fade duration={300}>
        <AcTable {...options} />
      </Fade>
    );
  }, [data, control_unit_types.is_busy, current_control_unit_type_software]);

  const getAddSoftwareUpdateButtonOptions = useMemo(() => {
    return {
      rel: 'ac-add-button',
      type: TYPES.BUTTON,
      theme: THEMES.ALPHA,
      title: 'New Software Update',
      disabled: cannot(PERMISSIONS.SOFTWARE.CREATE),
      callback: displayAddSoftwareUpdateModal,
    };
  }, [current_control_unit_type]);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  });

  return (
    <div className={getMainClassNames}>
      <AcContainer fluid>
        <AcRow className={'h-margin-bottom-25'}>
          <AcColumn xs={12} sm={6}>
            <AcHeading tag={'h2'} rank={5}>
              Software updates
            </AcHeading>
            <AcRichContent
              content={
                '<p>Available software updates for this control unit type.</p>'
              }
            />
          </AcColumn>

          <AcColumn xs={12} sm={6} className={'h-text--align-right'}>
            <AcButton {...getAddSoftwareUpdateButtonOptions}>
              <AcIcon icon={ICONS.PLUS} />
              <span>New Software Update</span>
            </AcButton>
          </AcColumn>
        </AcRow>

        <AcRow>
          <AcColumn xs={12}>
            <AcCard dense flat>
              {renderSoftwareTable}
            </AcCard>
          </AcColumn>
        </AcRow>
      </AcContainer>
    </div>
  );
};

export default withStore(observer(AcControlUnitTypeDetailSoftwareTab));
