// Imports => React
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Constants
import {
  ICONS,
  KEYS,
  MAP_OPTIONS,
  PERMISSIONS,
  DATETIME_FORMATS,
  SOFTWARE_KEYS,
  THEMES,
  TITLES,
  TYPES,
  VARIANTS,
  VISUALS,
} from '@constants';

// Imports => Utilities
import {
  AcUUID,
  AcIsSet,
  AcIsUndefined,
  AcFormatInternalURI,
  AcFormatMapURL,
  AcSortBy,
  AcFormatDate,
} from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Tabs
const AcControlUnitDetailLiveviewTab = loadable(() =>
  import('@tabs/control-unit/liveview/liveview.web')
);

// Imports => Molecules
const AcEditControlUnitModal = loadable(() =>
  import('@molecules/ac-edit-control-unit-modal/ac-edit-control-unit-modal.web')
);
const AcCheckPermissions = loadable(() =>
  import('@molecules/ac-check-permissions/ac-check-permissions.web')
);

// Imports => Components
const AcLocationMapWidget = loadable(() =>
  import('@components/ac-location-map-widget/ac-location-map-widget.web')
);
const AcDetailsCard = loadable(() =>
  import('@components/ac-details-card/ac-details-card.web')
);
const AcHeroVisual = loadable(() =>
  import('@components/ac-hero-visual/ac-hero-visual.web')
);
const AcCardContextualMenu = loadable(() =>
  import('@components/ac-card-contextual-menu/ac-card-contextual-menu.web')
);

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
const AcHeading = loadable(() => import('@atoms/ac-heading/ac-heading.web'));
const AcCard = loadable(() => import('@atoms/ac-card/ac-card.web'));
const AcRichContent = loadable(() =>
  import('@atoms/ac-rich-content/ac-rich-content.web')
);
const AcButton = loadable(() => import('@atoms/ac-button/ac-button.web'));
const AcIcon = loadable(() => import('@atoms/ac-icon/ac-icon.web'));
import { useRenderContracts } from '@components/ac-contract-table';
import {
  ConnectedExpDateModal,
  ConnectedExpRemoveDateModal,
} from '@components/ac-exp-date-modal';

// Imports => Assets/Files
import updateSoftwareManualC36 from '@assets/files/C-36-update-software.pdf';
import updateSoftwareManualC66 from '@assets/files/C-66-update-software.pdf';
import { PATHS } from '@src/constants/routes.constants';

const _CLASSES = {
  MAIN: 'ac-control-unit-detail-overview-tab',
};

let _lvTimer = null;

const AcControlUnitDetailOverviewTab = ({
  data,
  store: { contracts: contractStore, control_units, control_unit_types, ui },
}) => {
  const { can, cannot } = usePermissions();

  const displayRemoveExpirationDateModal = async (
    id,
    expires_at,
    name,
    equipmentId
  ) => {
    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: TITLES.EXPIRATION_DATE_REMOVE,
      body: (
        <ConnectedExpRemoveDateModal
          equipmentNo={parseInt(equipmentId, 10)}
          contractId={id}
          contractName={name}
          submit={contractStore.updateEquipmentExpDate}
          afterSubmit={control_units.get_by_id}
        />
      ),
      closeable: true,
      visible: true,
      actions: [],
    });
  };

  const displayExpirationDateModal = async (
    id,
    expires_at,
    name,
    equipmentId
  ) => {
    const expiresDate = AcFormatDate(
      expires_at || new Date(),
      DATETIME_FORMATS.DATE,
      DATETIME_FORMATS.RAW_DATE
    );
    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: TITLES.EXPIRATION_DATE_SET,
      body: (
        <ConnectedExpDateModal
          equipmentNo={parseInt(equipmentId, 10)}
          contractId={id}
          contractName={name}
          expires={new Date(expiresDate)}
          submit={contractStore.updateEquipmentExpDate}
          afterSubmit={control_units.get_by_id}
        />
      ),
      closeable: true,
      visible: true,
      actions: [],
    });
  };

  const contracts = useRenderContracts(data.contracts, [
    [
      {
        show: (data) => {
          const found = data.find((d) => d.key === 'expires_at');
          if (found && !found.value) {
            return true;
          }
          return false;
        },
        label: 'Set expiration date',
        field: KEYS.OBJECT,
        icon: ICONS.CALENDAR_CLOCK,
        callback: ({ id, expires_at, name }) => {
          displayExpirationDateModal(id, expires_at, name, data.id);
        },
      },
    ],
    [
      {
        show: (data) => {
          const found = data.find((d) => d.key === 'expires_at');
          if (found && found.value) {
            return true;
          }
          return false;
        },
        label: 'Remove expiration date',
        field: KEYS.OBJECT,
        icon: ICONS.CALENDAR_AVAILABLE,
        type: TYPES.DELETE,
        callback: ({ id, expires_at, name }) =>
          displayRemoveExpirationDateModal(id, expires_at, name, data.id),
      },
    ],
  ]);

  useEffect(() => {
    return () => {
      clearTimeout(_lvTimer);
    };
  }, []);

  const handleLiveView = (event) => {
    if (event && event.persist) event.persist();
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    if (!AcIsSet(data.live_view)) return;

    window.open(PATHS.CONTROL_UNIT_LIVEVIEW.replace(':id', data.id));
  };

  const displayEditModal = async (event) => {
    if (event && event.persist) event.persist();
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: `${TITLES.EDIT_CONTROL_UNIT}: ${data.object_no}`,
      body: (
        <AcEditControlUnitModal data={data} submit={control_units.update} />
      ),
      closeable: true,
      visible: true,
      actions: [],
      callback: () => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const handleUpdateSoftware = async () => {
    if (!AcIsSet(data)) return;
    if (!AcIsSet(data.equipment_type)) return;

    const { equipment_type } = data;
    await control_unit_types.get_software(equipment_type.id).then(() => {
      const { current_control_unit_type_software_list } = control_unit_types;

      let collection = current_control_unit_type_software_list.slice();

      if (cannot(PERMISSIONS.SOFTWARE.READ_UNAVAILABLE)) {
        collection = collection.filter(
          (version) => version.available_since !== '-'
        );
      }

      collection = AcSortBy({
        collection,
        field: 'name',
        direction: 'desc',
      });

      ui.multi_select({
        instance: control_units,
        collection,
        title: TITLES.SELECT_SOFTWARE_UPDATE,
        wide: true,
        introduction:
          '<p>Select the software update you want to push to this control unit.</p>',
        callback: () => {
          ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
        },
        multiple: false,
        verify_errors: true,
        confirm: {
          label: 'Push software update',
          callback: (update) => {
            return control_units
              .update_software(update, data)
              .then((response) => {
                return response;
              })
              .catch((error) => {
                return error;
              });
          },
        },
      });
    });
  };

  const handleDownloadSoftware = async () => {
    if (!AcIsSet(data)) return;
    if (!AcIsSet(data.equipment_type)) return;

    const { equipment_type } = data;
    await control_unit_types.get_software(equipment_type.id).then(() => {
      const { current_control_unit_type_software_list } = control_unit_types;

      let collection = current_control_unit_type_software_list.slice();
      collection = AcSortBy({
        collection,
        field: 'name',
        direction: 'desc',
      });

      ui.multi_select({
        instance: control_units,
        collection,
        title: TITLES.DOWNLOAD_SOFTWARE_UPDATE,
        wide: true,
        introduction: '<p>Select the software update you want to download.</p>',
        callback: () => {
          ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
        },
        multiple: false,
        verify_errors: true,
        confirm: {
          label: 'Download software update',
          callback: async (update) => {
            const item = update && update[0];
            return control_units
              .download_software(item, data)
              .then(({ data }) => {
                window.open(data.data.url);
              })
              .catch((error) => {
                return error;
              });
          },
        },
      });
    });
  };

  const handleDownloadManual = (series = null) => {
    let manual = updateSoftwareManualC36;

    if (series && series === '66') {
      manual = updateSoftwareManualC66;
    }

    const downloadManualLink = document.createElement('a');
    downloadManualLink.href = manual;
    downloadManualLink.setAttribute('download', manual);
    document.body.appendChild(downloadManualLink);
    downloadManualLink.click();
    downloadManualLink.parentNode.removeChild(downloadManualLink);
  };

  const renderLocationMapWidget = useMemo(() => {
    let result = {
      location: MAP_OPTIONS.center,
    };

    if (AcIsSet(data) && AcIsSet(data.location)) {
      const { location } = data;
      result.location = location;
    }

    return <AcLocationMapWidget {...result} entity={KEYS.CONTROL_UNITS} />;
  }, [data]);

  const getContextMenu = useMemo(() => {
    const { series } = data.equipment_type;

    const collection = [
      [
        {
          label: 'Push new software update',
          icon: ICONS.CAST,
          callback: handleUpdateSoftware,
          disabled: cannot(PERMISSIONS.SOFTWARE.PUSH),
        },
        {
          label: `Download manual`,
          icon: ICONS.DOWNLOAD_ALT,
          callback: () => handleDownloadManual(series),
          disabled: cannot(PERMISSIONS.SOFTWARE.READ),
        },
        {
          label: 'Download software update',
          icon: ICONS.DOWNLOAD_ALT,
          callback: handleDownloadSoftware,
          disabled: cannot(PERMISSIONS.SOFTWARE.DOWNLOAD),
        },
      ],
    ];

    return <AcCardContextualMenu collection={collection} />;
  }, [data, handleDownloadManual, handleUpdateSoftware]);

  const renderDetails = useMemo(() => {
    if (!data) return null;

    const {
      equipment_type,
      external_key,
      object_no,
      location,
      company,
      company_name,
      used_by,
      contract_type,
    } = data;

    const { series } = equipment_type;

    let items = [
      [
        {
          label: 'Object number',
          value: object_no || '-',
        },
        {
          label: series && series === '66' ? 'CB number' : 'PPCB number',
          value: external_key || '-',
        },
      ],
      [],
    ];

    let object_type = {
      label: 'Equipment type',
      value: (equipment_type && equipment_type.name) || '-',
    };

    if (can(PERMISSIONS.EQUIPMENT_TYPE.READ)) {
      const object_route =
        (equipment_type &&
          AcFormatInternalURI(
            {
              id: equipment_type.id,
              entity: KEYS.CONTROL_UNIT_TYPES,
              equipment_group: KEYS.CONTROL_UNITS,
            },
            'View object type'
          )) ||
        null;

      if (object_route)
        object_type = { ...object_type, type: TYPES.LINK, to: object_route };
    }
    items[0].push(object_type);

    if (AcIsSet(location)) {
      const map = {
        location: [location.lat, location.lng].join('  ,  '),
        url: AcFormatMapURL(location),
      };

      const maplink =
        (map.url &&
          `<a href="${map.url}" target="_blank"><span>${map.location}</span><i class="ac-icon ac-icon--open-in-new" /></a>`) ||
        '-';

      items[0].push({
        label: 'Location',
        value: maplink || '-',
      });
    }

    if (can(PERMISSIONS.COMPANY.READ_ALL)) {
      const company_route =
        (company &&
          AcFormatInternalURI(
            { id: company.id, entity: KEYS.COMPANY },
            'View company'
          )) ||
        null;

      if (!items?.[1]) items.push([]);
      items[1].push({
        label: 'Company',
        value: (company && company.name) || '-',
        type: TYPES.LINK,
        to: company_route,
      });
    }

    if (can(PERMISSIONS.COMPANY.READ)) {
      if (!AcIsUndefined(used_by)) {
        const used_by_route =
          (used_by?.id &&
            AcFormatInternalURI(
              { id: used_by.id, entity: KEYS.COMPANY },
              'View company'
            )) ||
          null;

        if (!used_by?.name) {
          items[1].push({
            label: 'Used by',
            value: used_by || '-',
          });
        } else {
          items[1].push({
            label: 'Used by',
            value: used_by?.name || '-',
            type: TYPES.LINK,
            to: used_by_route,
          });
        }
      }
    }

    if (can(PERMISSIONS.CONTRACT.READ_ALL)) {
      if (!AcIsUndefined(contract_type)) {
        items[1].push({
          label: 'Contract type',
          value: contract_type || '-',
        });
      }
    }

    const edit = can(PERMISSIONS.EQUIPMENT.MANAGE_DETAILS) && displayEditModal;

    return <AcDetailsCard title={TITLES.DETAILS} items={items} edit={edit} />;
  }, [data]);

  //   const renderContracts = useMemo(() => {
  //     if (!data) return null;
  //     if (!data.contracts) return null;
  //     const { contracts } = data;

  //     return (
  //       <AcCard>
  //         <AcConnectedContractTable contracts={contracts} />
  //       </AcCard>
  //     );
  //   }, [data]);

  const renderSoftwareDetails = useMemo(() => {
    if (!data) return null;

    const { software } = data;
    let key;
    let items = [];

    for (key in software) {
      if (!AcIsSet(SOFTWARE_KEYS[key])) continue;

      const label = SOFTWARE_KEYS[key];
      const value = software[key] || '-';

      const obj = { label, value };

      items.push(obj);
    }

    items = items.sort((a, b) => a.label.localeCompare(b.label));
    items = items.chunk(2);

    return (
      <AcDetailsCard
        title={TITLES.DETAILS}
        items={items}
        context={getContextMenu}
      />
    );
  }, [data, getContextMenu]);

  const getLiveViewButtonOptions = useMemo(() => {
    return {
      type: TYPES.BUTTON,
      theme: THEMES.OMEGA,
      variant: VARIANTS.TEXT,
      title: 'Open live view',
      disabled:
        !AcIsSet(data) ||
        !AcIsSet(data.live_view) ||
        data?.live_view === 'error',
      callback: handleLiveView,
    };
  }, [data, data.live_view]);

  const renderLiveView = useMemo(() => {
    if (!data) return null;

    const { series } = data.equipment_type;

    let ControlUnitVisualisation = VISUALS.CONTROL_UNIT_SCHEMATIC;

    if (series === '66') {
      ControlUnitVisualisation = VISUALS.CONTROL_UNIT_66_SCHEMATIC;
    }

    return (
      <AcContainer className={'h-padding-y-35'}>
        <AcRow className={'h-margin-bottom-25'}>
          <AcColumn className={'h-flex-h-align-center'}>
            <ControlUnitVisualisation />
          </AcColumn>
        </AcRow>

        {data?.live_view && data?.live_view !== 'error' && (
          <AcRow>
            <AcColumn className={'h-margin-bottom-10 h-flex-h-align-center'}>
              <AcButton {...getLiveViewButtonOptions}>
                <AcIcon icon={ICONS.OPEN_IN_NEW} />
                <span>Open live view</span>
              </AcButton>
            </AcColumn>
          </AcRow>
        )}

        <AcRow>
          <AcColumn className={'h-flex-h-align-center'}>
            <AcRichContent
              content={
                data?.live_view === 'error'
                  ? `<p>The live view is currently unavailable</p>`
                  : '<p>The live view will open in a new window.</p>'
              }
            />
          </AcColumn>
        </AcRow>
      </AcContainer>
    );
  }, [data]);

  const renderHeroVisual = useMemo(() => {
    if (!data) return null;

    const { equipment_type } = data;

    if (!equipment_type || !equipment_type.header_image) return null;

    return <AcHeroVisual image={equipment_type.header_image} />;
  }, [data]);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  });

  return (
    <div className={getMainClassNames}>
      <AcContainer fluid>
        <AcRow>
          <AcColumn>{renderLocationMapWidget}</AcColumn>
        </AcRow>
        <AcRow>
          <AcColumn xs={12} sm={6}>
            <AcRow>
              <AcColumn>
                <AcHeading tag={'h2'} rank={5} className={'h-margin-bottom-25'}>
                  Control unit details
                </AcHeading>
              </AcColumn>
            </AcRow>
            <AcRow>
              <AcColumn>{renderDetails}</AcColumn>
            </AcRow>
          </AcColumn>

          <AcColumn xs={12} sm={6}>
            <AcRow>
              <AcColumn>
                <AcHeading tag={'h2'} rank={5} className={'h-margin-bottom-25'}>
                  Live view
                </AcHeading>
              </AcColumn>
            </AcRow>
            <AcRow>
              <AcColumn>
                <AcCard>{renderLiveView}</AcCard>
              </AcColumn>
            </AcRow>
          </AcColumn>
        </AcRow>
        <AcRow>
          <AcCheckPermissions allowed={PERMISSIONS.SOFTWARE.READ}>
            <AcColumn xs={12} sm={6}>
              <AcRow>
                <AcColumn>
                  <AcHeading
                    tag={'h2'}
                    rank={5}
                    className={'h-margin-bottom-25'}
                  >
                    Software versions
                  </AcHeading>
                </AcColumn>
              </AcRow>
              <AcRow>
                <AcColumn>{renderSoftwareDetails}</AcColumn>
              </AcRow>
            </AcColumn>
          </AcCheckPermissions>

          {renderHeroVisual && (
            <AcColumn xs={12} sm={6}>
              <AcRow>
                <AcColumn>
                  <AcHeading
                    tag={'h2'}
                    rank={5}
                    className={'h-margin-bottom-25'}
                  >
                    Visualisation
                  </AcHeading>
                </AcColumn>
              </AcRow>

              <AcRow>
                <AcColumn>{renderHeroVisual}</AcColumn>
              </AcRow>
            </AcColumn>
          )}
        </AcRow>

        {contracts && (
          <AcRow>
            <AcColumn xs={12} sm={12}>
              <AcRow>
                <AcColumn>
                  <AcHeading
                    tag={'h2'}
                    rank={5}
                    className={'h-margin-bottom-25'}
                  >
                    Contracts
                  </AcHeading>
                </AcColumn>
              </AcRow>
              <AcRow>
                <AcColumn>
                  <AcCard dense flat>
                    {contracts}
                  </AcCard>
                </AcColumn>
              </AcRow>
            </AcColumn>
          </AcRow>
        )}
      </AcContainer>
    </div>
  );
};

export default withStore(observer(AcControlUnitDetailOverviewTab));
